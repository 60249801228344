const background = [
  {
    image:"https://i.imgur.com/iyY2j4I.jpeg",
    title: "bg1",
  },
  {
    image:"https://ik.imagekit.io/dehaexport/Background/tr:w-1200/2bg.jpg?updatedAt=1714573017298",
    title: "bg2",
  },
  {
    image:"https://ik.imagekit.io/dehaexport/Background/tr:w-800/3bg.jpg?updatedAt=1714573014505",
    title: "bg3",
  },
  {
    image:"https://ik.imagekit.io/dehaexport/Background/tr:w-1000/4bg.png?updatedAt=1714572992462",
    title: "bg4",
  },
  {
    image:"https://ik.imagekit.io/dehaexport/Background/5bg.png?updatedAt=1714572993414",
    title: "bg5",
  },
];
export default background;
