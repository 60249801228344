import { useMutation } from '@apollo/client';
import { deleteCommentMutation } from '../../../config/typeDef';

const useDeleteComment = () => {
  const [deleteComment, { loading, error }] = useMutation(deleteCommentMutation);

  const handleDeleteComment = async (id) => {
    try {
      await deleteComment({ variables: { id } });
      alert('Comment deleted successfully');
    } catch (err) {
      console.error('Error deleting comment:', err);
    }
  };

  return { handleDeleteComment, loading, error };
};

export default useDeleteComment;
