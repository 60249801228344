import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { getComment, addCommentMutation } from '../../config/typeDef';
import Loader from '../navbar/Loader'; // Import Loader

const CommentSection = ({ blogId }) => {
  const { data, loading, error } = useQuery(getComment, {
    variables: { id: blogId },
  });

  const [addComment] = useMutation(addCommentMutation);
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');

  const handleAddComment = async () => {
    try {
      await addComment({
        variables: { blog_id: blogId, name, comment },
        refetchQueries: [{ query: getComment, variables: { id: blogId } }],
      });
      setName('');
      setComment('');
    } catch (e) {
      console.error('Error adding comment:', e);
    }
  };

  if (loading) return <Loader />;

  if (error) {
    console.error('GraphQL Error:', error);
    return <p>Error: {error.message}</p>;
  }

  const blog = data?.blogs[0];
  const comments = blog?.commentRel || [];

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Comments</h2>
      {comments.length === 0 ? (
        <p>No comments yet.</p>
      ) : (
        comments.map((comment) => (
          <div className="d-flex flex-column mb-3" key={comment.id}>
            <div className="d-flex align-items-center mb-2">
              <img
                src={`https://picsum.photos/seed/${comment.id}/50`}
                className="rounded-circle me-3"
                style={{ width: '48px', height: '48px' }}
              />
              <div>
                <h5 className="mb-0">{comment.name}</h5>
                <p className="mb-0">{new Date(comment.created_at).toLocaleDateString()}</p>
              </div>
            </div>
            <p className="mt-2">{comment.comment}</p>
          </div>
        ))
      )}
      <h3 className="text-xl font-bold mb-4">Add a Comment</h3>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-default"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="input-group mb-3">
        <textarea
          className="form-control"
          placeholder="Leave a comment here"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <button type="button" className="btn btn-primary mb-4" onClick={handleAddComment}>
        Submit
      </button>
    </div>
  );
};

export default CommentSection;
