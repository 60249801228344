import React from "react";
import { useQuery } from "@apollo/client";
import { getComment } from "../../../config/typeDef";
import useDeleteComment from "./deleteCommentHandler";
import { useParams } from "react-router-dom";
import Loader from "../../navbar/Loader";

const DeleteComment = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(getComment, {
    variables: { id: parseInt(id) },
  });

  const { handleDeleteComment, loading: deleteLoading, error: deleteError } = useDeleteComment();

  if (loading || deleteLoading) return <Loader />;
  if (error) return <div>Error: {error.message}</div>;
  if (deleteError) return <div>Error deleting comment: {deleteError.message}</div>;

  const blog = data?.blogs[0];
  const comments = blog?.commentRel || [];

  console.log("Blog Data:", blog);
  console.log("Comments:", comments);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 ps-5 pt-2">Manage Comments</h2>
      {comments.length === 0 ? (
        <p>No comments yet.</p>
      ) : (
        <table className="table table-hover ms-5">
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Created At</th>
              <th>Comment</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {comments.map((comment) => (
              <tr key={comment.id}>
                <td>
                  <img src={`https://picsum.photos/seed/${comment.id}/50`} className="rounded-circle" style={{ width: "48px", height: "48px" }} />
                </td>
                <td>
                  <h5 className="mb-0">{comment.name}</h5>
                </td>
                <td>
                  <p className="mb-0">{new Date(comment.created_at).toLocaleString()}</p>
                </td>
                <td>{comment.comment}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleDeleteComment(comment.id)}
                    style={{ padding: "5px 10px", fontSize: "12px", width: "150px" }} // Inline CSS for reducing button size
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DeleteComment;
